import { useRef } from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Open_Sans } from 'next/font/google';
import localFont from 'next/font/local';
import Head from 'next/head';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { ToastBody } from '~/components/toast-body';
import { WithIdentifyAuthedUser } from '~/features/analytics/components/with-identify-authed-user';
import { useCheckUserCookies } from '~/features/cookies/hooks/use-check-user-cookies';
import { getShouldShowWelcomeModalCookie } from '~/features/cookies/utils/get-should-show-welcome-modal-cookie';
import { WithInternationalization } from '~/features/internationalization/with-internationalization';
import { useWelcomeTrap } from '~/features/welcome-flow/hooks/use-welcome-trap';
import { useCancelClientNavigation } from '~/hooks/use-cancel-client-navigation';
import { initDatadog } from '~/libs/datadog';
import { WithMocks } from '~/mocks/with-mocks';
import { theme } from '~/styles/theme';

const WelcomeModal = dynamic<{}>(() =>
  import('~/features/welcome-flow/components/welcome-modal').then((mod) => mod.WelcomeModal)
);
const ExperimentSettingsButton = dynamic<{}>(() =>
  import('~/features/experiments/components/experiment-settings-button').then((mod) => mod.ExperimentSettingsButton)
);
declare global {
  interface Window {
    airgap: import('@transcend-io/airgap.js-types').AirgapAPI;
    transcend: import('@transcend-io/airgap.js-types').ConsentManagerAPI;
    dataLayer: Array<Record<string, unknown>>;
    qualified: any; // Note: This is a global function that is set by the qualified script. Unfortunately, there are no type modules for this.
  }
}

export const openSans = Open_Sans({ subsets: ['latin'], weight: 'variable', display: 'swap' });
export const polySans = localFont({
  src: [
    {
      path: '../fonts/PolySans-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../fonts/PolySans-BoldItalic.otf',
      weight: '700',
      style: 'italic',
    },
    {
      path: '../fonts/PolySans-Median.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../fonts/PolySans-MedianItalic.otf',
      weight: '500',
      style: 'italic',
    },
    {
      path: '../fonts/PolySans-Neutral.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../fonts/PolySans-NeutralItalic.otf',
      weight: '400',
      style: 'italic',
    },
    {
      path: '../fonts/PolySans-Slim.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../fonts/PolySans-SlimItalic.otf',
      weight: '300',
      style: 'italic',
    },
  ],
});

const themeWithCustomizations = extendTheme(theme, {
  fonts: {
    heading: polySans.style.fontFamily,
    body: openSans.style.fontFamily,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: true,
      staleTime: Infinity,
      cacheTime: 60 * 1000,
    },
  },
});

export default function App({ Component, pageProps, router }: AppProps) {
  return (
    <WithMocks>
      <WithBootstrap>
        <Head>
          <meta content='width=device-width, initial-scale=1' name='viewport' />
        </Head>
        <QueryClientProvider client={queryClient}>
          <WithInternationalization>
            <ChakraProvider
              theme={themeWithCustomizations}
              toastOptions={{
                defaultOptions: {
                  position: 'top',
                  duration: 8000,
                  isClosable: true,
                  render: (props) => <ToastBody {...props} />,
                },
              }}
            >
              <WithIdentifyAuthedUser>
                {getShouldShowWelcomeModalCookie() && <WelcomeModal />}
                <Component {...pageProps} />
                {process.env.NEXT_PUBLIC_ENVIRONMENT_NAME !== 'production' && <ExperimentSettingsButton />}
              </WithIdentifyAuthedUser>
            </ChakraProvider>
          </WithInternationalization>
        </QueryClientProvider>
      </WithBootstrap>
    </WithMocks>
  );
}

function WithBootstrap({ children }: { children: React.ReactNode }) {
  const datadogLoaded = useRef(false);

  if (!datadogLoaded.current && typeof window !== 'undefined') {
    initDatadog();
    datadogLoaded.current = true;
  }

  useCancelClientNavigation();
  useCheckUserCookies();
  useWelcomeTrap();

  return <>{children}</>;
}
