import { ExperimentPage } from '~/cf-worker/types';

export const experimentedPages: ExperimentPage[] = [
  {
    name: 'Azure Gen AI Engineer Homepage Takeover',
    experimentKey: 'homepage_takeover',
    pathRegex: /^\/$/,
  },
  {
    experimentKey: 'ab_pd_coupon_page_redesign',
    name: 'PD Coupon Success Page Redesign AB Test',
    pathRegex: /^\/personalized-discount-success/,
  },
  {
    experimentKey: 'ab_plp_added_copy',
    name: 'PLP Added Value Copy AB Test',
    pathRegex:
      /^\/course\/(generative-ai--nd608|ai-programming-python-nanodegree--nd089|intro-to-programming-nanodegree--nd000|data-engineer-nanodegree--nd027|c-plus-plus-nanodegree--nd213)$/,
  },
];
